

<script>

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {searchFee, searchExpense} from '@/api/misc'

export default {


    emits: ['onSelected','onCleared'],

    props: {
        init_data : {
            type : Object,
            default: () => ({
                fee_code: '',
                fee_id  : '',
                style_class : {},
                placeholder : '',
                user_obj    : null
            })
        },

        s_type : {
            type : String,
            default : () => 'Fee'
        }
    },

    data() {
        return {
            fee_code: '',
            fee_id  : '',
            searched_data : []
        }
        
    },

    methods: {
        onSearched(new_value) {
            if (new_value == '') {
                this.$emit('onCleared')
                return
            }
            let search_data = {
                search_str : new_value
            }
            if (this.s_type == 'Expense') {
                searchExpense(search_data).then(res => {
                    this.searched_data = []
                    res.data.map((d) => {
                        this.searched_data.push({
                            label: `${d.name} (${d.code} - ${d.accounting_gl})`,
                            value: d.code,
                            fee  : d
                        })
                    })
                })
            } else {
                searchFee(search_data).then(res => {
                    this.searched_data = []
                    res.data.map((d) => {
                        this.searched_data.push({
                            label: `${d.name} (${d.code} - ${d.accounting_gl})`,
                            value: d.code,
                            fee  : d
                        })
                    })
                })
            }
            
        },

        onHit(evt) {
            evt.user_obj = this.init_data.user_obj
            this.$refs['vbt'].inputValue = evt.label
            this.$emit('onSelected', evt, this)
        }
    },

    created() {
       
    },

    mounted() {
        if (this.init_data && this.init_data.fee_code) {
            this.$refs['vbt'].inputValue = this.init_data.fee_code
        }
      
    },

    components: {
        VueBootstrapTypeahead
    },

    watch: {
        fee_code: function(new_value) { 
            this.onSearched(new_value)
        },
       
    },

   


}
</script>

<template>
     <vue-bootstrap-typeahead 
        ref="vbt"
        v-model="fee_code"
        :data="searched_data"
        :inputClass="init_data.style_class"
        :serializer="s => s.label"
        :foramterDisplay="s => s.label"
        :placeholder ="init_data.placeholder"
        @hit="onHit($event)"
        autocomplete="off"
        />
</template>