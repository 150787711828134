<script>


/**
 * Basic Info Edit component
 */

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import FeeAutoComplete from '@/components/fee-auto-complete-input'
import AgentAutoComplete from '@/components/agent-auto-complete-input'
import { required,
} from "vuelidate/lib/validators";


export default {

    emits: ['confirm', 'cancel'],

    props: ['credit'],
    components: {
        flatPickr,
        AgentAutoComplete,
        FeeAutoComplete
    },

    validations: {
        credit: {
            agent_id : {required},
            amount: { required },
            start_month: { required },
            end_month: { required },
            fee_code : {required}
        },
    },



    data() {

        return {
            submitted: false,
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },

            agent_choice: {},
            agents: [],
            agents_choice_list: [],
        }

    },
    methods: {
        formSubmit() {
            
            this.submitted = true;
            this.$v.$touch();
            console.log(this.$v)
            if (this.$v.$invalid == false) {
                this.$emit('confirm', this.credit)
            }
        },

        onSelectedAgent(agent) {
            this.credit.agent = agent.agent
            this.credit.agent_id = agent.agent.id
        },


        onGLSelected(e) {
            this.credit.fee_code = e.value
        }

    },

    created() {


    },

    mounted() {
     
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">

                            <label for="choices-agent-input" class="form-label">Agent</label>
                            <AgentAutoComplete @onSelected="onSelectedAgent" :init_data="{style_class :  {'is-invalid': $v.credit.agent_id.$error }}" autocomplete="off" />
                            <div v-if="$v.credit.agent_id.$error" class="invalid-feedback">
                                <span v-if="!$v.credit.agent_id.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01" v-model="credit.amount" type="text" class="form-control"
                                placeholder="$" value="Mark" :class="{
                                    'is-invalid': submitted && $v.credit.amount.$error,
                                }" />
                            <div v-if="submitted && $v.credit.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.credit.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom03">Start Date</label>
                            <flat-pickr v-model="credit.start_month" placeholder="Select a date" class="form-control"
                                :config="dpconfig" :class="{
                                    'is-invalid': submitted && $v.credit.start_month.$error,
                                }"></flat-pickr>
                            <div v-if="submitted && $v.credit.start_month.$error" class="invalid-feedback">
                                <span v-if="!$v.credit.start_month.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom04">End Date</label>
                            <flat-pickr v-model="credit.end_month" :config="dpconfig" placeholder="Select a date" class="form-control" :class="{
                                'is-invalid': $v.credit.end_month.$error,
                            }"></flat-pickr>

                            <div v-if="submitted && $v.credit.end_month.$error" class="invalid-feedback">
                                <span v-if="!$v.credit.end_month.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label >Creit Fee Code</label>
                            <FeeAutoComplete  placeholder="" v-model="credit.fee_code" @onSelected="onGLSelected" :input_class="{
                                'is-invalid':  $v.credit.fee_code.$error,
                            }" />
                            <div v-if="submitted && $v.credit.fee_code.$error" class="invalid-feedback">
                                <span v-if="!$v.credit.fee_code.required">This value is required.</span>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input id="validationCustom01" v-model="credit.desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>