var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"choices-agent-input"}},[_vm._v("Agent")]),_c('AgentAutoComplete',{attrs:{"init_data":{style_class :  {'is-invalid': _vm.$v.credit.agent_id.$error }},"autocomplete":"off"},on:{"onSelected":_vm.onSelectedAgent}}),(_vm.$v.credit.agent_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.credit.agent_id.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credit.amount),expression:"credit.amount"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.credit.amount.$error,
                            },attrs:{"id":"validationCustom01","type":"text","placeholder":"$","value":"Mark"},domProps:{"value":(_vm.credit.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credit, "amount", $event.target.value)}}}),(_vm.submitted && _vm.$v.credit.amount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.credit.amount.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom03"}},[_vm._v("Start Date")]),_c('flat-pickr',{staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.credit.start_month.$error,
                            },attrs:{"placeholder":"Select a date","config":_vm.dpconfig},model:{value:(_vm.credit.start_month),callback:function ($$v) {_vm.$set(_vm.credit, "start_month", $$v)},expression:"credit.start_month"}}),(_vm.submitted && _vm.$v.credit.start_month.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.credit.start_month.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom04"}},[_vm._v("End Date")]),_c('flat-pickr',{staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.credit.end_month.$error,
                        },attrs:{"config":_vm.dpconfig,"placeholder":"Select a date"},model:{value:(_vm.credit.end_month),callback:function ($$v) {_vm.$set(_vm.credit, "end_month", $$v)},expression:"credit.end_month"}}),(_vm.submitted && _vm.$v.credit.end_month.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.credit.end_month.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Creit Fee Code")]),_c('FeeAutoComplete',{attrs:{"placeholder":"","input_class":{
                            'is-invalid':  _vm.$v.credit.fee_code.$error,
                        }},on:{"onSelected":_vm.onGLSelected},model:{value:(_vm.credit.fee_code),callback:function ($$v) {_vm.$set(_vm.credit, "fee_code", $$v)},expression:"credit.fee_code"}}),(_vm.submitted && _vm.$v.credit.fee_code.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.credit.fee_code.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credit.desc),expression:"credit.desc"}],staticClass:"form-control",attrs:{"id":"validationCustom01","type":"text","placeholder":"","value":""},domProps:{"value":(_vm.credit.desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credit, "desc", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex flex-wrap gap-2"},[_c('b-button',{attrs:{"variant":"secondary","type":"button"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Submit")])],1)]),_c('div',{staticClass:"col-md-4"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }